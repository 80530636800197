import { ValidationMixin } from '@/shared/mixins';
import { Component } from 'vue-property-decorator';

@Component
export default class ContactValidator extends ValidationMixin {
    /**
     * Règles de validation pour l'email.
     *
     * @private
     * @memberof ContactValidator
     */
    private emailRules = [
        (v: string | any) => this.required(v) || 'Le mail de confirmation est obligatoire',
        (v: string | any) => this.isEmail(v) || 'Le mail de confirmation n\'est pas valide',
    ];

    /**
     * Vérifie si le champ téléphone est bien renseigné.
     *
     * @memberof ContactValidator
     */
    public telephoneRules = [
        (v: string | any) => this.required(v) || 'Le téléphone est obligatoire',
        (v: string | any) => this.isPhoneNumber(v) || 'Le téléphone n\'est pas valide',
    ];

    /**
     * Vérifie si le champ Nom est bien renseigné.
     *
     * @memberof ContactValidator
     */
    public nomRules = [
        (v: string | any) => this.required(v) || 'Le nom est obligatoire',
    ];

    /**
     * Vérifie si le champ Prénom est bien renseigné.
     *
     * @memberof ContactValidator
     */
    public prenomRules = [
        (v: string | any) => this.required(v) || 'Le prénom est obligatoire',
    ];

    /**
     * Vérifie si le champ société est bien renseignée.
     *
     * @memberof ContactValidator
     */
    public societeRules = [
        (v: string | any) => this.required(v) || 'La société est obligatoire',
    ];

    /**
     * Vérifie si l'objet de la demande est bien renseigné.
     *
     * @memberof ContactValidator
     */
    public objetDemandeRules = [
        (v: string | any) =>
            this.required(v) || 'L\'objet de la demande est obligatoire',
    ];

    /**
     * Vérifie si le message est bien renseigné.
     *
     * @memberof ContactValidator
     */
    public messageRules = [
        (v: string | any) => this.required(v) || 'Le message est obligatoire',
    ];

    /**
     * Vérifie si le département est bien renseigné.
     *
     * @memberof ContactValidator
     */
    public departementRules = [
        (v: string | any) => this.required(v) || 'Le département est obligatoire',
    ];
}

import { Component, Mixins, Prop } from 'vue-property-decorator';
import template from './Contact.Template.vue';
import ContactValidator from './Contact.Validator';
import { ApiService } from '@/services/base/ApiService';
import { Departement, ObjetDemande, DemandeUtilisateur } from '@/models';
import { CommonMixin, CompteValidationMixin } from '@/shared/mixins';
import { Getter } from 'vuex-class';
import { getterKeyReferentiel } from '@/store/modules/referentiel/ReferentielStore';
import { TypeValeurReferentielle } from '@/shared/models';

@Component({
    ...template,
    name: 'Contact',
})
export default class Contact extends Mixins(ContactValidator, CompteValidationMixin, CommonMixin) {
    public $refs!: {
        form: HTMLFormElement;
    };

    public loading = false;

    /**
     * La liste d'objets demande.
     *
     * @type {string[]}
     * @memberof Contact
     */
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.ObjetDemande))
    public objetsDemande: ObjetDemande[];
    /**
     * La liste des départements.
     *
     * @type {Departement[]}
     * @memberof Contact
     */
    @Getter(getterKeyReferentiel(TypeValeurReferentielle.Departement))
    public departements: Departement[];

    /**
     * La demande de l'utilisateur.
     *
     * @type {DemandeUtilisateur}
     * @memberof Contact
     */
    public demandeUtilisateur: DemandeUtilisateur = new DemandeUtilisateur();

    /**
     *  Si le formulaire de contact est pour un installateur.
     */
    @Prop({default: false}) public readonly estInstallateur!: boolean;

    /**
     * Initialise une nouvelle instance du Contact
     */
    constructor() {
        super();
    }
    /**
     * Montage de la vue.
     */
    public mounted() {
        this.getValeursReferentielles(TypeValeurReferentielle.ObjetDemande);
        this.getValeursReferentielles(TypeValeurReferentielle.Departement);
    }

    /**
     * Crée une nouvelle demande de contact.
     *
     * @returns {Promise<DemandeUtilisateur>}
     * @memberof Contact
     */
    public submit() {
        this.loading = true;
        if (this.$refs.form.validate()) {
            const demandeUtilisateurService = new ApiService<DemandeUtilisateur>(
                'demandeUtilisateur/creer'
            );
            demandeUtilisateurService
                .post(this.demandeUtilisateur)
                .then(() => {
                    (this.$router as any).push({ name: 'home' });
                })
                .catch(() => {
                    throw new Error('Une erreur s\'est produite lors de l\'envoi du courrier électronique');
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
